<template>
  <b-overlay :show="showLoader">
    <template #overlay>
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon" />
        <p id="cancel-label">Optimizing Route. Please wait a moment.</p>
      </div>
    </template>
    <b-container fluid>
      <b-card>
        <b-row class="align-items-center">
          <b-col>
            <v-select
              v-model="selected_order_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(selected_order_type) => selected_order_type.value"
              :options="orderTypeOptions"
              :clearable="true"
              class="font-small-3 w-100mr-1"
              label="label"
              placeholder="Select Order Type"
              @input="
                resetClients();
                displayMarker();
              "
              multiple
            >
              <template #option="option">
                <span>{{ option.label }}</span>
              </template>
            </v-select>
          </b-col>

          <b-col v-if="userData.id == '1042'">
            <v-select
              v-model="selected_branch"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="branchOptions"
              :clearable="true"
              @input="
                resetClients();
                displayMarker();
              "
              class="font-small-3 w-100 mr-1"
              :reduce="(branchOptions) => branchOptions.value"
              placeholder="Select Outlet"
              label="label"
            >
              <template #option="option">
                <span>{{ option.label }}</span>
              </template>
            </v-select>
          </b-col>

          <b-col>
            <v-select
              v-model="selected_status_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusTypeOptions"
              :clearable="true"
              class="font-small-3 w-100 mr-1"
              :reduce="(statusTypeOptions) => statusTypeOptions.value"
              placeholder="Select Status Type"
              label="label"
              @input="
                resetClients();
                displayMarker();
              "
            >
              <template #option="option">
                <span>{{ option.label }}</span>
              </template>
            </v-select>
          </b-col>

          <b-col>
            <flat-pickr
              v-model="filter_date"
              :config="{
                dateFormat: 'Y-m-d',
                defaultDate: 'today',
              }"
              class="form-control"
              @input="
                resetClients();
                displayMarker();
              "
            />
          </b-col>

          <b-col class="d-flex align-items-center">
            SELECTED ORDERS :
            <b-badge variant="primary" class="ml-1">{{ counter }}</b-badge>
            <b-tooltip target="eye-icon" triggers="hover" placement="top">
              View List of Orders
            </b-tooltip>
            <b-icon-eye
              id="eye-icon"
              class="ml-1"
              v-b-modal.OrderList
            ></b-icon-eye>
          </b-col>

          <b-col class="d-flex justify-content-end align-items-center">
            <b-button
              variant="gradient-primary"
              @click.prevent="optimizeRouting()"
            >
              <feather-icon icon="MapPinIcon" size="14" class="text-white" />
              <span class=""> Optimize Route </span>
            </b-button>
          </b-col>
        </b-row>
        <b-form-group label="">
          <b-form-checkbox
            v-for="client in clients"
            :key="client.id"
            v-model="selected_user"
            :value="client.id"
            name="flavour-4a"
            inline
            @input="displayMarker()"
          >
            {{ client.name }}
          </b-form-checkbox>
        </b-form-group>
        <div ref="map" style="height: 800px" class="mt-1" />
      </b-card>

      <!-- List of Orders Modal -->
      <b-modal
        id="OrderList"
        title="Selected Orders List"
        no-enforce-focus
        size="lg"
        @hidden="removeFocus"
      >
        <b-card
          v-for="(order, index) in selectedOrders"
          :key="index"
          class="mb-2"
        >
          <div>
            <b-badge variant="primary" class="mr-1">OrderID: </b-badge>
            <strong>{{ order.id }}</strong>
          </div>
          <hr />
          <div class="d-flex align-items-center">
            <b-badge variant="secondary" class="mr-1">Address: </b-badge>
            <span
              class="text-truncate d-inline-block"
              style="max-width: 600px"
              v-b-tooltip.hover
              :title="order.address"
            >
              {{ order.address }}
            </span>
          </div>
        </b-card>
      </b-modal>
    </b-container>
  </b-overlay>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BButton, BModal, VBModal } from "bootstrap-vue";
import { Loader } from "@googlemaps/js-api-loader";
import OverlappingMarkerSpiderfier from "overlapping-marker-spiderfier";
import { MAP_STYLING } from "@/common/Constants";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { success, error, info, requestError } from "@/common/SwalOptions";
import { getUserData } from "@/auth/utils";
export default {
  name: "Map",
  components: {
    vSelect,
    BModal,
    BButton,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
  },
  data() {
    return {
      userData: {},
      clients: [],
      showLoader: false,
      form: {
        remarks: null,
        driver_id: null,
        delivery_date: new Date().toISOString().split("T")[0],
        stops: [],
      },
      drivers: [],
      orderTypeOptions: [
        { label: "Distributor", value: "Distributor" },
        { label: "Outlet", value: "Outlet" },
        { label: "Singapore", value: "Singapore" },
        { label: "Agrofun", value: "Agrofun" },
        { label: "Agrobazaar", value: "Agrobazaar" },
        { label: "B2B", value: "B2B" },
      ],
      branchOptions: [],
      statusTypeOptions: [
        { label: "All", value: "All" },
        { label: "Active", value: "Active" },
        { label: "In Transit", value: "In Transit" },
      ],
      selected: [],
      markers: [],
      order_data: [],
      oms: null,
      map: null,
      counter: 0,
      filter_date: new Date().toISOString().split("T")[0],
      filtered_date: null,
      selected_user: [],
      selected_order_type: [],
      selected_status_type: "All",
      selectedOrders: [],
      selected_branch: "",
      svg: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
      defaultColor: "#ed3b4d",
      sc: "#63181f",
      drawingManager: null,
    };
  },
  mounted() {
    const loader = new Loader({
      apiKey: "AIzaSyAuxm1Z531Z0hLTTIgbfjg_beIl1zmaX7M",
      version: "weekly",
      libraries: ["drawing"],
    });

    loader.load().then(() => {
      this.userData = getUserData();
      this.selected_branch = this.userData.branch_id;
      this.getBranches();
      this.displayMarker();
    });
  },
  methods: {
    async marker_event(obj, sw) {
      obj.setIcon({
        path: this.svg,
        strokeColor: this.sc,
        strokeWeight: sw,
        strokeOpacity: 1,
        fillColor: this.defaultColor,
        fillOpacity: 1,
        scale: 0.5,
      });
    },
    async optimizeRouting() {
      if (this.counter > 0) {
        this.$router
          .push({
            name: "optimize-route",
            query: {
              orders: JSON.stringify(this.selectedOrders),
              clients: this.selected_user,
              date: this.filtered_date,
              counter: this.counter,
            },
          })
          .catch((err) => console.log(err));
      } else {
        this.$swal(
          info({
            text: "Select Orders In The Map.",
          })
        );
      }
    },
    async onRectangleComplete(Bounds) {
      this.selectedOrders = {};
      for (const index in this.markers) {
        if (!this.markers.hasOwnProperty(index)) continue;
        const marker = this.order_data[index];
        if (Bounds.contains(this.markers[index].getPosition()) == true) {
          if (marker.id in this.selected) {
            this.markers[marker.id].setIcon({
              path: this.svg,
              strokeColor: this.sc,
              strokeWeight: 1,
              strokeOpacity: 1,
              fillColor: this.defaultColor,
              fillOpacity: 1,
              scale: 0.45,
            });
            delete this.selected[marker.id];
          } else {
            this.markers[marker.id].setIcon({
              path: this.svg,
              strokeColor: this.sc,
              strokeWeight: 3,
              strokeOpacity: 1,
              fillColor: this.defaultColor,
              fillOpacity: 1,
              scale: 0.45,
            });
            this.selected[marker.id] = marker;
          }
        }
        this.counter = Object.keys(this.selected).length;
        this.selectedOrders = Object.values(this.selected).map((order) => ({
          id: order.id,
          address: order.recipient.complete_address,
          lat: order.recipient.lat,
          lng: order.recipient.lng,
          recipient_id: order.recipient.id,
          branch_id: order.should_route_by,
        }));
      }
    },
    async displayMarker() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // reset the counter and selected orders
      this.counter = 0;
      this.selected = [];
      this.filtered_date = this.filter_date;
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat: 2.731813, lng: 102.252502 },
        zoom: 8,
        styles: MAP_STYLING,
      });
      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
      });
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.RECTANGLE],
        },
      });
      this.drawingManager.setMap(this.map);
      const that = this;
      google.maps.event.addListener(
        this.drawingManager,
        "rectanglecomplete",
        (evt) => {
          that.onRectangleComplete(evt.getBounds());
          evt.setMap(null);
        }
      );
      try {
        const response = await this.$http.post(`/admin/get_orders`, {
          status: this.selected_status_type,
          order_type: this.selected_order_type,
          date: this.filter_date,
          clients: this.selected_user,
        });
        const orders = response.data.data;
        this.markers = [];
        const processedOrderIds = new Set();
        orders.forEach((data) => {
          // If this order has already been processed, skip it
          if (processedOrderIds.has(data.id)) {
            return;
          }

          // Mark this order as processed
          processedOrderIds.add(data.id);

          if (this.clients.length < 1) {
            this.clients.push(data.user);
          } else {
            let found = false;
            this.clients.forEach((index) => {
              if (index.id == data.user.id) {
                found = true;
                return false;
              }
            });
            if (!found) {
              this.clients.push(data.user);
            }
          }
          // console.log(this.clients)
          this.order_data[data.id] = data;
          this.markers[data.id] = new google.maps.Marker({
            position: data.recipient,
            icon: {
              path: this.svg,
              strokeColor: this.sc,
              strokeWeight: 0.5,
              strokeOpacity: 1,
              fillColor: this.defaultColor,
              fillOpacity: 1,
              scale: 0.5,
            },
          });

          const info_window = new google.maps.InfoWindow({
            maxWidth: 200,
            disableAutoPan: true,
          });
          this.markers[data.id].addListener("mouseout", () => {
            info_window.close();
          });
          const self = this;
          this.markers[data.id].addListener("mouseover", () => {
            const date_info =
              data.order_type == "Singapore"
                ? `<span style="color:#330000;">PICKUP DATE : <b>${data.pickup_date}</b></span>`
                : `<span style="color:#330000;">DELIVERY DATE : <b>${data.delivery_date}</b></span>`;
            info_window.setContent(
              `<div class="card">
                      <span style="color:#330000;">CLIENT : <strong>[${
                        data.user.name
                      }]</strong></span>
                        <span style="color:#330000;">ORDER : <b>${data.id} [${
                data.order_type === "B2B"
                  ? `B${data.shipment_id}`
                  : data.order_type
              }]</b></span>
                       <span style="color:#330000;">NAME : <b>${
                         data.recipient.first_name
                       }</b></span>
                       <span style="color:#330000;">ADDRESS : <b>${
                         data.recipient.complete_address
                       }</b></span>
                       <span style="color:#330000;">REMARKS : <b>${
                         data.remarks ?? "Not Set"
                       }</b></span>
                       ${date_info}
                    </div>`
            );
            info_window.open(self.map, self.markers[data.id]);
          });
          this.markers[data.id].setMap(this.map);
          this.oms.addMarker(this.markers[data.id]);
          const that = this;
          console.log(this.markers);
          this.markers[data.id].addListener("click", function (e) {
            if (data.id in that.selected) {
              that.marker_event(this, 0.5);
              delete that.selected[data.id];
              that.counter = Object.keys(that.selected).length;
              that.selectedOrders = Object.values(that.selected).map(
                (order) => ({
                  id: order.id,
                  address: order.recipient.complete_address,
                  lat: order.recipient.lat,
                  lng: order.recipient.lng,
                  recipient_id: order.recipient.id,
                  branch_id: order.should_route_by,
                })
              );
            } else {
              that.marker_event(this, 3);
              that.selected[data.id] = data;
              that.counter = Object.keys(that.selected).length;
              that.selectedOrders = Object.values(that.selected).map(
                (order) => ({
                  id: order.id,
                  address: order.recipient.complete_address,
                  lat: order.recipient.lat,
                  lng: order.recipient.lng,
                  recipient_id: order.recipient.id,
                  branch_id: order.should_route_by,
                })
              );
            }
          });
        });
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async handleSubmit() {
      this.$refs.routing.validate().then((success) => {
        if (success) {
          this.$refs.route_modal.hide();
          this.selected.forEach((index) => {
            this.form.stops.push({
              order_id: index.id,
              branch_id: index.should_route_by,
              recipient_id: index.recipient.id,
              address: index.recipient.complete_address,
              area: index.recipient.address_area,
              postal_code: index.recipient.address_postcode,
              lat: index.recipient.lat,
              lng: index.recipient.lng,
            });
          });
          this.saveRoute();
        }
      });
    },
    async saveRoute() {
      if (this.selected_order_type != "Singapore") {
        this.form.delivery_date = this.filtered_date;
      }
      this.showLoader = true;
      const response = await this.$http.post("create_route", this.form);
      this.showLoader = false;
      if (response.data.status) {
        this.$swal(
          success({
            text: response.data.message,
          })
        ).then((result) => {
          if (result.isConfirmed) {
            location.reload();
          }
        });
      } else if (response.data.errors) {
        const { errors } = response.data;
        for (const a in errors) {
          const error = errors[a];
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error[0],
              icon: "XIcon",
              variant: "warning",
            },
          });
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: "XIcon",
            variant: "warning",
          },
        });
      }
    },
    async resetClients() {
      this.clients = [];
      this.selected_user = [];
    },
    async getBranches() {
      const response = await this.$http.get("branch");
      if (response.data.success === false) {
        this.$swal(requestError);
        this.isLoading = false;
        return;
      }

      this.branchOptions = response.data.data.map((items) => ({
        ...items,
        label: items.address,
        value: items.id,
      }));

      console.log(this.branchOptions);
    },
    async getDrivers() {
      this.drivers = [];
      const response = await this.$http.get(
        "get_driver_by_branch/" + this.selected_branch
      );
      if (response.data.success === false) {
        this.$swal(requestError);
        this.isLoading = false;
        return;
      }

      this.drivers = response.data.drivers.map((items) => ({
        ...items,
        label: items.name,
      }));
    },
    removeFocus() {
      document.getElementById("eye-icon").setAttribute("tabindex", "-1");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>